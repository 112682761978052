@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
.LandingView {
    display: flex;
    position: relative;
}

.LandingView-signin {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    position: relative;
    height: calc(100vh - 64px);
}

.LandingView-signin-title {
    padding-top: 10%;
}

.LandingView-signin-title, .LandingView-signin-caption {
    padding-bottom: 12px;
}

.LandingView-signin-title span {
    color: rgb(var(--highlight-color));
}

.LandingView-unsupported {
    color: #e74c3c;
}

.LandingView-scroll {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
}

.LandingView-scroll h4, .LandingView-scroll svg.MuiSvgIcon-root {
    color: rgba(var(--foreground-color), 0.5);
    cursor: default;
}

.LandingView-transition {
    padding-top: 64px;
}

.LandingView-info {
    padding-bottom: 64px;
}

.LandingView-info-image {
    padding: 64px;
}

.LandingView-privacy p {
    color: rgba(var(--foreground-color), 0.5);
}

#LandingView-signin-button {
    height: 50px;
    padding-top: 12px;
    padding-bottom: 20%;
}

.ContextMenu {
    position: absolute;
    background-color: rgb(var(--midground-color));
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    z-index: 3;
}

.ContextMenu-item {
    display: flex;
    padding: 8px;
    cursor: pointer;
}

.ContextMenu-item .MuiSvgIcon-root {
    margin-right: 8px;
}

.ContextMenu-message {
    display: flex;
    justify-content: center;
    padding: 16px;
}

.ContextMenu-message p {
    color: rgba(var(--foreground-color), 0.5);
}

.ContextMenu-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 180px;
    overflow: auto;
}

.ContextMenu-body::-webkit-scrollbar-track {
    margin-top: 8px;
    margin-bottom: 8px;
}

.ContextMenu-marginHackContainer {
    display: flex;
}

.ContextMenu-marginHackContainer-marginHack {
    width: 8px;
}

@-moz-document url-prefix() {
    .ContextMenu-marginHackContainer-marginHack {
        width: 0px;
    }
}

.title {
    pointer-events: none;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.AddVideo {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 14px;
}

.AddVideo-deck {
    display: flex;
    flex-grow: 1;
}

.AddVideo-deck-button {
    display: flex;
    align-self: center;
}

.AddVideo-deck-button:first-child .MuiSvgIcon-root {
    margin-left: -4px;
    cursor: default;
}

.AddVideo-deck-button.close .MuiSvgIcon-root {
    cursor: pointer;
}

.AddVideo-deck-input {
    margin-left: 2px;
    margin-right: 2px;
}

.AddVideo-results {
    padding-top: 8px;
}

.ImportContext-item p:last-child {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.ImportContext-item p:last-child .MuiSvgIcon-root {
    margin-left: 8px;
    margin-right: 0;
}

.MoveContext {
    width: 272px;
}

.MoveContext-item p:last-child {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.MoveContext-item p:last-child .MuiSvgIcon-root {
    margin-left: 8px;
    margin-right: 0;
}

.Breadcrumb {
    display: flex;
    flex-shrink: 0;
}

.Breadcrumb-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.Breadcrumb-button h3 {
    min-width: 1px; /* edit cursor needs room to display when empty */
}

.Breadcrumb-button h3:focus {
    outline: none;
}

.Breadcrumb-button-mask {
    position: absolute;
    width: 100%;
    height: 100%;
}

.Breadcrumb-chevron {
    display: flex;
    align-self: center;
}

.Breadcrumb-chevron .MuiSvgIcon-root {
    cursor: default;
}

.Breadcrumb:last-child .Breadcrumb-chevron {
    display: none;
}

.Header {
    display: flex;
}

.Header-tree {
    align-self: center;
    margin-right: 16px;
    overflow: hidden;
}

.Header-tree-inner {
    display: flex;
}

.Header-buttonContainer {
    display: flex;
}

.Header-buttonContainer-button {
    display: flex;
    flex-shrink: 0;
    margin-left: 8px;
    align-items: center;
    cursor: pointer;
}

.Header-buttonContainer-button .MuiSvgIcon-root {
    align-self: center;
    margin: 8px 0px 8px 8px;
}

.FolderItem {
    display: flex;
    position: relative;
    width: calc(100% / 2 - 20px);
    height: calc(1.5em + 24px);
    margin-top: 16px;
    margin-right: 16px;
    border: 2px solid rgb(var(--highlight-color));
    border-radius: 64px;
    color: rgb(var(--foreground-color));
    background-color: rgb(var(--background-color));
    cursor: pointer;
}

.FolderItem h5 {
    width: calc(100% - 80px);
    padding-left: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.FolderItem h5:focus {
    outline: none;
    text-overflow: clip;
}

.FolderItem-text {
    flex-grow: 1;
    align-self: center;
    width: 100%;
    text-align: center;
}

.FolderItem-text .placeholder:after{
    content: attr(placeholder);
    color: rgb(var(--foreground-color), 0.5);
}

.FolderItem-buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 100%;
}

.FolderItem-buttonContainer-buttons {
    visibility: hidden;
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 6px;
}

.FolderItem-buttonContainer-buttons h4 {
    display: flex;
    align-items: center;
}

.FolderItem-buttonContainer:hover .FolderItem-buttonContainer-buttons,
.drag .FolderItem-buttonContainer-buttons,
.active {
    visibility: visible;
}

.FolderItem-buttonContainer-buttons-drag .MuiSvgIcon-root {
    cursor: -webkit-grab;
    cursor: grab;
}

.PlaylistItem {
    display: flex;
    position: relative;
    width: calc(100% / 4 - 16px);
    margin-top: 16px;
    margin-right: 16px;
    background-color: rgb(var(--highlight-color));
    box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.PlaylistItem::after { /* makes the square */
    content: "";
    margin-bottom: 100%;
}

.PlaylistItem h5 {
    width: calc(100% - 32px);
    padding-left: 16px;
    color: #000000;
    word-break: break-word;
}

.PlaylistItem h5:focus {
    outline: none;
}

.PlaylistItem-text {
    flex-grow: 1;
    align-self: center;
    width: 100%;
    text-align: center;
}

.PlaylistItem-text .placeholder:after{
    content: attr(placeholder);
    color: rgb(0, 0, 0, 0.5);
}

.PlaylistItem-buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 100%;
}

.PlaylistItem-buttonContainer-buttons {
    visibility: hidden;
    display: flex;
    justify-content: space-between;
    margin: 8px;
}

.PlaylistItem-buttonContainer-buttons .MuiSvgIcon-root {
    color: #000000;
}

.PlaylistItem-buttonContainer:hover .PlaylistItem-buttonContainer-buttons,
.drag .PlaylistItem-buttonContainer-buttons,
.active {
    visibility: visible;
}

.PlaylistItem-buttonContainer-buttons-drag .MuiSvgIcon-root {
    cursor: -webkit-grab;
    cursor: grab;
}

.drag {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

@media only screen and (max-width: 1366px) {
    .FolderItem {
        width: calc(100% / 1 - 18px); 
    }
    
    .PlaylistItem {
        width: calc(100% / 3 - 16px);
    }
}

.Grid {
    display: flex;
    flex-direction: column;
    margin-top: -2px;
    padding-right: 8px;
    overflow-y: overlay;
}

/* firefox does not support overlay scroll */
@-moz-document url-prefix() {
    .Grid {
        padding-right: 0px;
        overflow-y: auto;
    }
}

.Grid::-webkit-scrollbar-track {
    margin-top: 16px;
}

.Grid-body {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 4px;
}

.ActionButtons {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    margin: 8px;
}

.ActionButtons-dragHandle {
    margin-left: 16px;
    opacity: 0.15;
}

.ActionButtons-dragHandle .MuiSvgIcon-root {
    cursor: row-resize;
}

.VideoItem {
    display: flex;
    height: 70px;
    overflow: hidden;
    background-color: rgb(var(--background-color));
}

.VideoItem.active {
    background-image: linear-gradient(to right, rgba(var(--foreground-color), 0.175), rgba(0, 0, 0, 0));
}

.VideoItem.drag {
    background-image: none;
    background-color: rgb(var(--midground-color));
}

.VideoItem-thumbnail {
    height: 100%;
}

.VideoItem-thumbnail img {
    display: block;
    position: relative;
    top: calc(-1 / 6 * 100%);
    height: calc(4 / 3 * 100%); /* convert 4:3 to 16:9 */
}

.VideoItem-body {
    display: flex;
    flex-grow: 1;
    min-width: 0;
    height: 70px;
}

.VideoItem-body-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-self: center;
    min-width: 0;
    height: 70px;
}

.VideoItem-body-info p {
    margin: 4px 4px 4px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em;
}

.VideoItem-body-info-author p {
    color: rgba(var(--foreground-color), 0.5);
}

.VideoItem-body-info-buttons {
    justify-content: flex-end;
}

.VideoList {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.VideoList-list {
    outline: none;
    overflow-y: overlay;
}

.drag {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.LibraryContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.LibraryContainer-subHeader {
    display: flex;
    position: relative;
    padding-top: 16px;
}

.LibraryContainer-subHeader-button {
    display: flex;
    position: relative;
    right: 4px;
    margin-right: 12px;
    cursor: pointer;
    z-index: 2;
}

.LibraryContainer-subHeader-blockHack {
    position: absolute;
    bottom: -14px;
    width: 100%;
    height: 16px;
    background-color: rgb(var(--background-color));
    z-index: 1;
}

.SearchContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 12px;
}

.SearchContainer-results {
    display: flex;
    height: 100%;
    padding-top: 8px;
}

.SearchContainer-search {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
}

.SearchContainer-search-bar {
    display: flex;
    flex-grow: 1;
}

.SearchContainer-search-bar-input {
    margin-right: 2px;
}

.SearchContainer-search-bar-button {
    display: flex;
    align-self: center;
}

.LeftPane {
    display: flex;
    min-width: calc(60% - 16px);
    max-width: calc(60% - 16px);
    padding-right: 16px;
}

.LeftPane-menu {
    display: flex;
    flex-direction: column;
    margin-top: calc(4em - 4px);
}

.LeftPane-menu-button {
    margin: 24px;
    cursor: pointer;
}

.LeftPane-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
}

.LeftPane-container-body {
    flex-grow: 1;
    overflow: hidden;
}

.sign-out {
    transform: rotate(180deg);
}

.ProgressBar {
    position: relative;
    flex-basis: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: rgba(var(--foreground-color), 0.15);
}

.ProgressBar div {
    height: 4px;
    background-color: rgb(var(--highlight-color));
    border-radius: 2px;
}

.ProgressBar input {
    border: 0;
}

.ProgressBar-slider {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 100%;
    height: 4px; /* give extra space for user to click */
    margin: 0;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: none;
    outline: none;
    cursor: pointer;
}

.ProgressBar-slider::-webkit-slider-thumb {
    width: 0;
    -webkit-appearance: none;
            appearance: none;
}

.ProgressBar-slider::-moz-range-thumb {
    width: 0;
    visibility: hidden;
}

.VolumeControl {
    display: flex;
    flex-basis: 96px;
}

.VolumeControl input {
    border: 0;
    padding: 0px;
}

.VolumeControl-bar {
    display: flex;
    align-self: center;
    padding-left: 4px;
}

.VolumeControl-bar-slider {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: rgba(var(--foreground-color), 0.15);
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: none;
    cursor: pointer;
}
  
.VolumeControl-bar-slider::-webkit-slider-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%; 
    -webkit-appearance: none; 
            appearance: none;
    background-color: rgb(var(--foreground-color));
}

.VolumeControl-bar-slider::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border: 0;
    border-radius: 50%; 
    visibility: none;
    background-color: rgb(var(--foreground-color));
}

.ControlDeck {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.ControlDeck-header {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: baseline;
    min-width: 0;
    height: 20px; /* fix height to handle special characters in video title */
    margin: 8px 0 8px 0;
}

.ControlDeck-header h5 {
    line-height: 1.2; /* also to handle special characters */
}

.ControlDeck-header-center {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ControlDeck-header-left {
    padding-right: 8px;
}

.ControlDeck-header-right {
    padding-left: 8px;
}

.ControlDeck-footer {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
}

.ControlDeck-footer-left, .ControlDeck-footer-center, .ControlDeck-footer-right {
    display: flex;
    flex-basis: 128px;
    margin: 8px 0 8px 0;
}

.ControlDeck-footer-left {
    justify-content: flex-start;
}

.ControlDeck-footer-center {
    justify-content: center;
}

.ControlDeck-footer-right {
    justify-content: flex-end;
}

.VideoContainer {
    position: relative;
    padding-bottom: 56.25%;
    z-index: 1;
    background-color: rgb(var(--foreground-color), 0.15);
}

.VideoContainer iframe {
    position: absolute;
    width: 100%;
    height: 100%;
}

.QueueBar {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.QueueBar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.QueueBar-header-right {
    display: flex;
}

.QueueBar-header-buttons {
    display: flex;
    margin: 8px 0 8px 0;
}

.QueueBar-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.RightPane {
    display: flex;
    flex-direction: column;
    min-width: 40%;
    max-width: 40%;
}

.MuiSvgIcon-root.active {
    color: rgb(var(--highlight-color));
}

.App {
    display: flex;
    width: calc(100% - 144px - 83px);
    height: calc(100vh - 144px);
    padding: 72px;
    padding-right: calc(72px + 83px);
    /* allows scrollbar on firefox to change color */
    background-color: rgb(var(--background-color));
}

.Page {
    display: flex;
    flex-direction: column;
    padding: 64px 20% 64px 20%;
    text-align: justify;
    overflow: auto;
}

.Page h4, .Page h5 {
    line-height: 2em;
}

.Page img {
    width: 100%;
    height: auto;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.horizontal-divider {
    width: 100%;
    padding-top: 1px;
    background-color: rgba(var(--foreground-color), 0.15);
}

.vertical-divider {
    position: relative;
    top: 20%;
    height: 60%;
    padding-left: 1px;
    background-color: rgba(var(--foreground-color), 0.15);
}

@media only screen and (max-width: 1366px) {
    .App {
        width: calc(100% - 96px - 78px);
        height: calc(100vh - 96px);
        padding: 48px;
        padding-right: calc(48px + 78px);
    }
}

@media only screen and (max-width: 1024px) {
    .App {
        width: calc(100% - 48px - 78px);
        height: calc(100vh - 48px);
        padding: 24px;
        padding-right: calc(24px + 78px);
    }
}

* {
	margin: 0;
	padding: 0;
}

body {
    --foreground-color: 0, 0, 0;
    --midground-color: 255, 255, 255;
    --background-color: 255, 255, 255;
    --highlight-color: 	156, 241, 150;
    overflow-x: hidden;
}

body.dark-mode {
    --foreground-color: 255, 255, 255;
    --midground-color: 38, 38, 38;
    --background-color: 0, 0, 0;
    --highlight-color: 156, 241, 150;
}

body {
    height: 100vh;
    min-width: 1024px;
	font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: rgb(var(--foreground-color));
    background-color: rgb(var(--background-color));
}

p {
    font-size: 1em;
}

h1, h1 .MuiSvgIcon-root {
    font-size: 8em;
}

h2, h2 .MuiSvgIcon-root {
    font-size: 4em;
}

h3, h3 .MuiSvgIcon-root {
    font-size: 2em;
}

h4, h4 .MuiSvgIcon-root {
    font-size: 1.5em;
    font-weight: lighter;
}

h5, h5 .MuiSvgIcon-root {
    font-size: 1.15em;
    font-weight: lighter;
}

a {
    color: rgb(var(--highlight-color));
    text-decoration: none;
}

ul {
    padding-left: 48px;
}

input {
    flex-grow: 1;
    padding: 4px;
    border: 1px solid rgb(var(--foreground-color));
    outline: none;
}

input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5)
}

input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5)
}

input::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5)
}

input::placeholder {
    color: rgba(0, 0, 0, 0.5)
}

svg.MuiSvgIcon-root {
    display: flex;
    font-size: 1.25em;
    color: rgb(var(--foreground-color));
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgb(var(--foreground-color));
}

@media only screen and (max-width: 1366px) {
    body {
        font-size: 12px;
    }
}

