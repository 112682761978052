.Header {
    display: flex;
}

.Header-tree {
    align-self: center;
    margin-right: 16px;
    overflow: hidden;
}

.Header-tree-inner {
    display: flex;
}

.Header-buttonContainer {
    display: flex;
}

.Header-buttonContainer-button {
    display: flex;
    flex-shrink: 0;
    margin-left: 8px;
    align-items: center;
    cursor: pointer;
}

.Header-buttonContainer-button .MuiSvgIcon-root {
    align-self: center;
    margin: 8px 0px 8px 8px;
}
