.Grid {
    display: flex;
    flex-direction: column;
    margin-top: -2px;
    padding-right: 8px;
    overflow-y: overlay;
}

/* firefox does not support overlay scroll */
@-moz-document url-prefix() {
    .Grid {
        padding-right: 0px;
        overflow-y: auto;
    }
}

.Grid::-webkit-scrollbar-track {
    margin-top: 16px;
}

.Grid-body {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 4px;
}
