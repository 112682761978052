.FolderItem {
    display: flex;
    position: relative;
    width: calc(100% / 2 - 20px);
    height: calc(1.5em + 24px);
    margin-top: 16px;
    margin-right: 16px;
    border: 2px solid rgb(var(--highlight-color));
    border-radius: 64px;
    color: rgb(var(--foreground-color));
    background-color: rgb(var(--background-color));
    cursor: pointer;
}

.FolderItem h5 {
    width: calc(100% - 80px);
    padding-left: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.FolderItem h5:focus {
    outline: none;
    text-overflow: clip;
}

.FolderItem-text {
    flex-grow: 1;
    align-self: center;
    width: 100%;
    text-align: center;
}

.FolderItem-text .placeholder:after{
    content: attr(placeholder);
    color: rgb(var(--foreground-color), 0.5);
}

.FolderItem-buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 100%;
}

.FolderItem-buttonContainer-buttons {
    visibility: hidden;
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 6px;
}

.FolderItem-buttonContainer-buttons h4 {
    display: flex;
    align-items: center;
}

.FolderItem-buttonContainer:hover .FolderItem-buttonContainer-buttons,
.drag .FolderItem-buttonContainer-buttons,
.active {
    visibility: visible;
}

.FolderItem-buttonContainer-buttons-drag .MuiSvgIcon-root {
    cursor: grab;
}

.PlaylistItem {
    display: flex;
    position: relative;
    width: calc(100% / 4 - 16px);
    margin-top: 16px;
    margin-right: 16px;
    background-color: rgb(var(--highlight-color));
    box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.PlaylistItem::after { /* makes the square */
    content: "";
    margin-bottom: 100%;
}

.PlaylistItem h5 {
    width: calc(100% - 32px);
    padding-left: 16px;
    color: #000000;
    word-break: break-word;
}

.PlaylistItem h5:focus {
    outline: none;
}

.PlaylistItem-text {
    flex-grow: 1;
    align-self: center;
    width: 100%;
    text-align: center;
}

.PlaylistItem-text .placeholder:after{
    content: attr(placeholder);
    color: rgb(0, 0, 0, 0.5);
}

.PlaylistItem-buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 100%;
}

.PlaylistItem-buttonContainer-buttons {
    visibility: hidden;
    display: flex;
    justify-content: space-between;
    margin: 8px;
}

.PlaylistItem-buttonContainer-buttons .MuiSvgIcon-root {
    color: #000000;
}

.PlaylistItem-buttonContainer:hover .PlaylistItem-buttonContainer-buttons,
.drag .PlaylistItem-buttonContainer-buttons,
.active {
    visibility: visible;
}

.PlaylistItem-buttonContainer-buttons-drag .MuiSvgIcon-root {
    cursor: grab;
}

.drag {
    cursor: grabbing;
}

@media only screen and (max-width: 1366px) {
    .FolderItem {
        width: calc(100% / 1 - 18px); 
    }
    
    .PlaylistItem {
        width: calc(100% / 3 - 16px);
    }
}
