.ImportContext-item p:last-child {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.ImportContext-item p:last-child .MuiSvgIcon-root {
    margin-left: 8px;
    margin-right: 0;
}
