.VideoItem {
    display: flex;
    height: 70px;
    overflow: hidden;
    background-color: rgb(var(--background-color));
}

.VideoItem.active {
    background-image: linear-gradient(to right, rgba(var(--foreground-color), 0.175), rgba(0, 0, 0, 0));
}

.VideoItem.drag {
    background-image: none;
    background-color: rgb(var(--midground-color));
}

.VideoItem-thumbnail {
    height: 100%;
}

.VideoItem-thumbnail img {
    display: block;
    position: relative;
    top: calc(-1 / 6 * 100%);
    height: calc(4 / 3 * 100%); /* convert 4:3 to 16:9 */
}

.VideoItem-body {
    display: flex;
    flex-grow: 1;
    min-width: 0;
    height: 70px;
}

.VideoItem-body-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-self: center;
    min-width: 0;
    height: 70px;
}

.VideoItem-body-info p {
    margin: 4px 4px 4px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em;
}

.VideoItem-body-info-author p {
    color: rgba(var(--foreground-color), 0.5);
}

.VideoItem-body-info-buttons {
    justify-content: flex-end;
}
