.ProgressBar {
    position: relative;
    flex-basis: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: rgba(var(--foreground-color), 0.15);
}

.ProgressBar div {
    height: 4px;
    background-color: rgb(var(--highlight-color));
    border-radius: 2px;
}

.ProgressBar input {
    border: 0;
}

.ProgressBar-slider {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 100%;
    height: 4px; /* give extra space for user to click */
    margin: 0;
    appearance: none;
    background: none;
    outline: none;
    cursor: pointer;
}

.ProgressBar-slider::-webkit-slider-thumb {
    width: 0;
    appearance: none;
}

.ProgressBar-slider::-moz-range-thumb {
    width: 0;
    visibility: hidden;
}
