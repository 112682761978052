.ActionButtons {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    margin: 8px;
}

.ActionButtons-dragHandle {
    margin-left: 16px;
    opacity: 0.15;
}

.ActionButtons-dragHandle .MuiSvgIcon-root {
    cursor: row-resize;
}
