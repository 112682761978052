.RightPane {
    display: flex;
    flex-direction: column;
    min-width: 40%;
    max-width: 40%;
}

.MuiSvgIcon-root.active {
    color: rgb(var(--highlight-color));
}
