.LandingView {
    display: flex;
    position: relative;
}

.LandingView-signin {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    position: relative;
    height: calc(100vh - 64px);
}

.LandingView-signin-title {
    padding-top: 10%;
}

.LandingView-signin-title, .LandingView-signin-caption {
    padding-bottom: 12px;
}

.LandingView-signin-title span {
    color: rgb(var(--highlight-color));
}

.LandingView-unsupported {
    color: #e74c3c;
}

.LandingView-scroll {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
}

.LandingView-scroll h4, .LandingView-scroll svg.MuiSvgIcon-root {
    color: rgba(var(--foreground-color), 0.5);
    cursor: default;
}

.LandingView-transition {
    padding-top: 64px;
}

.LandingView-info {
    padding-bottom: 64px;
}

.LandingView-info-image {
    padding: 64px;
}

.LandingView-privacy p {
    color: rgba(var(--foreground-color), 0.5);
}

#LandingView-signin-button {
    height: 50px;
    padding-top: 12px;
    padding-bottom: 20%;
}
