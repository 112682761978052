.LibraryContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.LibraryContainer-subHeader {
    display: flex;
    position: relative;
    padding-top: 16px;
}

.LibraryContainer-subHeader-button {
    display: flex;
    position: relative;
    right: 4px;
    margin-right: 12px;
    cursor: pointer;
    z-index: 2;
}

.LibraryContainer-subHeader-blockHack {
    position: absolute;
    bottom: -14px;
    width: 100%;
    height: 16px;
    background-color: rgb(var(--background-color));
    z-index: 1;
}
