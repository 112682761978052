.App {
    display: flex;
    width: calc(100% - 144px - 83px);
    height: calc(100vh - 144px);
    padding: 72px;
    padding-right: calc(72px + 83px);
    /* allows scrollbar on firefox to change color */
    background-color: rgb(var(--background-color));
}

.Page {
    display: flex;
    flex-direction: column;
    padding: 64px 20% 64px 20%;
    text-align: justify;
    overflow: auto;
}

.Page h4, .Page h5 {
    line-height: 2em;
}

.Page img {
    width: 100%;
    height: auto;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.horizontal-divider {
    width: 100%;
    padding-top: 1px;
    background-color: rgba(var(--foreground-color), 0.15);
}

.vertical-divider {
    position: relative;
    top: 20%;
    height: 60%;
    padding-left: 1px;
    background-color: rgba(var(--foreground-color), 0.15);
}

@media only screen and (max-width: 1366px) {
    .App {
        width: calc(100% - 96px - 78px);
        height: calc(100vh - 96px);
        padding: 48px;
        padding-right: calc(48px + 78px);
    }
}

@media only screen and (max-width: 1024px) {
    .App {
        width: calc(100% - 48px - 78px);
        height: calc(100vh - 48px);
        padding: 24px;
        padding-right: calc(24px + 78px);
    }
}
