.LeftPane {
    display: flex;
    min-width: calc(60% - 16px);
    max-width: calc(60% - 16px);
    padding-right: 16px;
}

.LeftPane-menu {
    display: flex;
    flex-direction: column;
    margin-top: calc(4em - 4px);
}

.LeftPane-menu-button {
    margin: 24px;
    cursor: pointer;
}

.LeftPane-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
}

.LeftPane-container-body {
    flex-grow: 1;
    overflow: hidden;
}

.sign-out {
    transform: rotate(180deg);
}
