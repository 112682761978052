.SearchContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 12px;
}

.SearchContainer-results {
    display: flex;
    height: 100%;
    padding-top: 8px;
}

.SearchContainer-search {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
}

.SearchContainer-search-bar {
    display: flex;
    flex-grow: 1;
}

.SearchContainer-search-bar-input {
    margin-right: 2px;
}

.SearchContainer-search-bar-button {
    display: flex;
    align-self: center;
}
