.VolumeControl {
    display: flex;
    flex-basis: 96px;
}

.VolumeControl input {
    border: 0;
    padding: 0px;
}

.VolumeControl-bar {
    display: flex;
    align-self: center;
    padding-left: 4px;
}

.VolumeControl-bar-slider {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: rgba(var(--foreground-color), 0.15);
    appearance: none;
    outline: none;
    cursor: pointer;
}
  
.VolumeControl-bar-slider::-webkit-slider-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%; 
    appearance: none;
    background-color: rgb(var(--foreground-color));
}

.VolumeControl-bar-slider::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border: 0;
    border-radius: 50%; 
    visibility: none;
    background-color: rgb(var(--foreground-color));
}
