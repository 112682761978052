.ContextMenu {
    position: absolute;
    background-color: rgb(var(--midground-color));
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    z-index: 3;
}

.ContextMenu-item {
    display: flex;
    padding: 8px;
    cursor: pointer;
}

.ContextMenu-item .MuiSvgIcon-root {
    margin-right: 8px;
}

.ContextMenu-message {
    display: flex;
    justify-content: center;
    padding: 16px;
}

.ContextMenu-message p {
    color: rgba(var(--foreground-color), 0.5);
}

.ContextMenu-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 180px;
    overflow: auto;
}

.ContextMenu-body::-webkit-scrollbar-track {
    margin-top: 8px;
    margin-bottom: 8px;
}

.ContextMenu-marginHackContainer {
    display: flex;
}

.ContextMenu-marginHackContainer-marginHack {
    width: 8px;
}

@-moz-document url-prefix() {
    .ContextMenu-marginHackContainer-marginHack {
        width: 0px;
    }
}

.title {
    pointer-events: none;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
